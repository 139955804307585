$background-color_1: grey;

@keyframes dot {
    0% {
        background-color: grey;
        transform: scale(1);
    }
    50% {
        background-color: #ffffff;
        transform: scale(1.3);
    }
    100% {
        background-color: grey;
        transform: scale(1);
    }
}
.loading
{
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 5px;
    height: 100%;
    width: 100%;

    .loading-dot {
        animation: dot ease-in-out 1s infinite;
        background-color: $background-color_1;
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        &:nth-of-type(2) {
            animation-delay: 0.2s;
        }
        &:nth-of-type(3) {
            animation-delay: 0.3s;
        }
    }
}
