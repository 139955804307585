@import "Styles/style.scss";

.resourcesDropdown {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 48px;

    .links {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding-top: 16px;
        .item {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
            color: $lightColor;
            .title {
                @include selectFont("Blinker", 400);
                font-size: 18px;
                line-height: 150%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            .content {
                @include selectFont("Blinker", 300);
                font-size: 14px;
                line-height: 155%;
                letter-spacing: 0.28px;
                opacity: 0.7;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

    .additionalContent {
        padding: 12px;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        background: rgba(255, 255, 255, 0.15);
        .imageContainer {
            overflow: hidden;
            margin-bottom: 11px;
            img {
                font-style: italic;
            }
        }

        .additionalContentTitle {
            margin-bottom: 8px;
            @include selectFont("Blinker", 400);
            font-size: 24px;
            line-height: normal;
            background: linear-gradient(
                180deg,
                #fff 0%,
                rgba(255, 255, 255, 0.45) 100%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .additionalContentContent {
            margin-bottom: 16px;
            @include selectFont("Blinker", 300);
            font-size: 14px;
            line-height: 155%;
            letter-spacing: 0.28px;
            opacity: 0.7;
        }
    }

    @include responsive("lg") {
        display: none;
    }
}
