@import "Styles/style.scss";

.footer {
  padding: 80px 0;
  position: relative;
  .content {
    position: relative;
    z-index: 1;
    margin-bottom: 44px;
    @extend %flex-between;
    gap: 10%;
    .left {
        flex: 0.44;
      max-width: 40%;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      img {
        margin-bottom: 16px;
        width: 70%;
      }
      p {
        @include selectFont("Blinker", 400);
        font-size: 16px;
        width: 87%;
        line-height: 155%;
        opacity: 0.7;
      }

      @include responsive("sm") {
        max-width: 100%;
        margin-bottom: 24px;
      }
    }

    .right {
      flex: 0.56;
      display: flex;
      gap: 40px;

      >div {
        &:first-of-type {
          ul {
            height: 100px;
          }
        }
      }
    }
    @include responsive("xxl") {
      gap: 6%;
      .left {
        flex: 0.42;
      }

      .right {
        flex: 0.51;
        justify-content: space-between;
        gap: 40px;
      }
    }
  }

  .subFooter {
    position: relative;
    z-index: 1;
    padding-top: 32px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    @extend %flex-between;

    p {
      @include selectFont("Blinker", 400);
      font-size: 14px;
      line-height: 150%;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 24px;

      @include responsive("sm") {
        gap: 16px;
      }

      li {
        @include selectFont("Blinker", 400);
        font-size: 14px;
        line-height: 150%;
      }
    }
  }

  @include responsive("md") {
    padding: 60px 0;

    .content {
      margin-bottom: 32px;
      flex-direction: column;
      justify-content: flex-start;
      gap: 40px;

      .right {
        flex: 1;
        flex-direction: column;
        gap: 28px;
        width: 100%;
      }
    }

    .subFooter {
      flex-direction: column;
      gap: 12px;
      p {
        font-size: 12px;
      }
      ul {
        text-align: center;
        li {
          font-size: 12px;
        }
      }
    }
  }
  .footerBackground,
  .footerBackgroundForMobile {
    @extend %position-absolute;
    z-index: -1;
    object-fit: cover;
  }
  .footerBackground {
    @include responsive("md") {
      display: none;
    }
  }
  .footerBackgroundForMobile {
    display: none;
    @include responsive("md") {
      display: block;
    }
  }
  .redEllipse {
    position: fixed;
    left: 71px;
    bottom: -564px;
    z-index: -1;

    @include responsive("sm") {
      left: -232px;
      bottom: -167px;
    }
  }
  .movEllipse {
    position: fixed;
    right: -158px;
    top: -335px;
    z-index: -1;

    @include responsive("sm") {
      right: -310px;
      top: -247px;
    }
  }
}
