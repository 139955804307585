@import "Styles/style.scss";

.marqueeContainer {
  @extend %flex-center;
  width: 100%;
  height: 80px;
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  z-index: 1;
  -webkit-animation: color-change-3x 4s linear infinite alternate both;
  animation: color-change-3x 4s linear infinite alternate both;

  @-webkit-keyframes color-change-3x {
    0% {
      background: #481929;
    }
    100% {
      background: #2c1d38;
    }
  }
  @keyframes color-change-3x {
    0% {
      background: #481929;
    }
    100% {
      background: #2c1d38;
    }
  }

  @include responsive("lg") {
    height: 60px;
  }

  .wave {
    background: rgb(255 255 255 / 15%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 2em;
    animation: wave 5s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;

    @keyframes wave {
      2% {
        transform: translateX(1);
      }

      25% {
        transform: translateX(-25%);
      }

      50% {
        transform: translateX(-50%);
      }

      75% {
        transform: translateX(-25%);
      }

      100% {
        transform: translateX(1);
      }
  }
  }

  .redEllipse {
    width: 682px;
    height: 682px;
    border-radius: 50%;
    opacity: 0.33;
    background: #9414ff;
    filter: blur(250px);
    position: absolute;
    z-index: -1;
    right: 61px;
    bottom: -321px;
  }

  .movEllipse {
    width: 682px;
    height: 682px;
    border-radius: 50%;
    opacity: 0.33;
    background: #ff005b;
    filter: blur(250px);
    position: absolute;
    z-index: -1;
    left: 62px;
    bottom: -306px;
  }

  /* Safari 9-15 */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .redEllipse {
      background: #9414ff;
    }

    .movEllipse {
      background: #ff005b;
    }
  }

  /* Safari 15+ */
  @supports (-webkit-backdrop-filter: blur(68px)) or
    (backdrop-filter: blur(68px)) {
    .redEllipse {
      background: rgba(148, 20, 255, 0.5);
      -webkit-filter: blur(68px);
      filter: blur(68px);
    }

    .movEllipse {
      background: rgba(255, 0, 91, 0.5);
      -webkit-filter: blur(68px);
      filter: blur(68px);
    }
  }

  /* Edge 12-15 */
  @supports (-ms-ime-align: auto) {
    .redEllipse {
      background: #9414ff;
    }

    .movEllipse {
      background: #ff005b;
    }
  }

  .marquee {
    display: flex;
    overflow: hidden;
    user-select: none;

    .marqueeGroup {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      transform: translateX(0%);
      justify-content: space-between;
      min-width: 100%;
      animation: scroll 50s linear infinite;
    }
  }
}

.textContainer {
  @extend %flex-center;
  gap: 32px;
  flex-direction: row-reverse;

  @include responsive("lg") {
    gap: 20px;
  }

  .marqueeText {
    @include selectFont("Blinker", 700);
    font-size: 24px;
    font-style: normal;
    line-height: 100%;
    text-transform: uppercase;
    background: linear-gradient(
      180deg,
      #fff 0%,
      rgba(255, 255, 255, 0.45) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include responsive("lg") {
      font-size: 24px;
    }
  }

  .circle {
    @extend %flex-center;
    width: 80px;
    height: 100%;
    gap: 4px;
    border-radius: 100px;
    // border: 1px solid rgba(255, 255, 255, 0.4);
    // background: rgba(255, 255, 255, 0.3);
    margin-right: 32px;

    @include responsive("lg") {
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }
  }

  .circleTwo {
    @extend %flex-center;
    flex-direction: column;
    gap: 4px;
    width: 80px;
    height: 100%;
    border-radius: 100px;
    // border: 1px solid #fff;
    // background: rgba(255, 255, 255, 0.35);

    @include responsive("lg") {
      width: 60px;
      height: 60px;
    }

    img {
      width: 60px;

      @include responsive("lg") {
        width: 60px;
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-80%);
  }
}