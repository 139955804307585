@import "Styles/style.scss";

.button {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1.125rem;
  background: $orangeLight;
  color: $lightColor;
  outline: none;
  border: none;
  cursor: pointer;
  @include selectFont("Blinker", 500);
  font-size: 1.5rem;

  &.gradient {
    border: 1px solid #fff;
    background: rgba(255, 84, 0, 0.2);
    backdrop-filter: blur(16.5px);
    border-image: radial-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0));
    border-image-slice: 1;
  }
  &.withEllipses {
    position: relative;
    background-color: $darkColor;
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    overflow: hidden;

    .ellipsesText {
      position: relative;
      z-index: 1;
    }

    .ellipsesContainer {
      svg {
        position: absolute;

        &:first-child {
          right: 0px;
          top: -78px;
        }

        &:last-child {
          left: 7px;
          bottom: -80px;
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}
