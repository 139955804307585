::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #4b4b4b;
}

a {
    :is(:focus, :hover) {
        text-decoration: none;
    }
}
