@import "Styles/style.scss";

.layout {
    position: relative;
    display: flex;
    flex-direction: column;

    .main {
        position: relative;
        flex: 1;
        min-height: 100vh;
    }

    .position {
        width: 100%;
        position: absolute;
        z-index: 1000;
    }
}

.lazyFallbackWrapper
{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $darkColor;
    overflow-x: hidden;

    svg {
        width: 250px;
    }
}

.leftEllipse {
    position: absolute;
    left: -444px;
    bottom: 240px;
    z-index: 1;
}
.rightEllipse {
    position: absolute;
    top: -37px;
    right: -137px;
    z-index: 1;
}
