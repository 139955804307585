@import "Styles/style.scss";

.menu {
  .title {
    @include selectFont("Blinker", 500);
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 24px;
    text-align: left;
  }

  .multiMenu {
    display: flex;
    gap: 40px;
  }

  .links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;

    @include responsive("sm") {
      &.textLink {
        flex-direction: column;
      }
    }

    .link {
      a {
        display: flex;
        align-items: center;
        gap: 12px;
        opacity: 0.7;
        .text {
          @include selectFont("Blinker", 400);
          font-size: 14px;
          line-height: 150%;
        }
      }
    }
  }

  @include responsive("md") {
    &.socialMediaLinks {
      padding-top: 20px;
      text-align: center;
      .links {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;

        @include responsive("md") {
          justify-content: space-around;
        }

        .link {
          a {
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
    .multiMenu {
      flex-direction: column;
    }
  }
}
