@import "partials/mixins";
@import "partials/pseudo";
@import "partials/fonts";
@import "partials/Inheritance";
@import "partials/overwrite";
@import "partials/colors";
@import "partials/BreakPoints";

:root {
    color-scheme: only light;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    outline: none;
    list-style: none;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: $darkColor;
    color: $lightColor;
    overflow-x: hidden;
    color-scheme: light;
}

button {
    cursor: pointer;
}

/* Disable zoom in in mobile */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px !important;
}
