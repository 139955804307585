@import "Styles/style.scss";
.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-top: 100px;
  padding-right: 28px;
  padding-left: 28px;
  border-radius: 6px;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  position: relative;
  @include selectFont("Blinker");
  transition: all 0.5s ease-in-out;
  .navbarSubContainer {
    @extend %flex-center;
    z-index: 2;
  }
  .navbarLogo {
    @extend %flex-center;
    .menuIcon {
      display: none;
      transform: scale(1.5);
      cursor: pointer;
    }
    @include responsive("lg") {
      width: 100%;
      justify-content: space-between;

      .menuIcon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .verticalLine {
    width: 1px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.6);
    margin-right: 20px;
    margin-left: 20px;
    @include responsive("lg") {
      display: none;
    }
  }
  .navbarLinks {
    @extend %flex-center;
    width: 100%;
    li {
      padding: 8px 16px;
      font-size: 14px;

      span {
        @extend %flex-center;
        justify-content: flex-start;
        gap: 8px;
        color: $lightColor;
        transition: all ease-in-out 0.3s;
        text-decoration: none;
        cursor: pointer;

        svg {
          transition: all ease-in-out 0.3s;

          path {
            transition: all ease-in-out 0.3s;
          }
        }

        &.activeDropdown {
          color: $orangeLight !important;
          position: relative;
          svg {
            transform: rotate(180deg);
            path {
              fill: $orangeLight;
            }
          }

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: $orangeLight;
            bottom: -30px;
            left: 0;
          }
        }
      }
    }
  }
  .buttonsContainer {
    @extend %flex-center;
    gap: 12px;
    z-index: 2;
  }
  .ellipseBig {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    div {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      margin: -0px -28px;
      @include responsive("lg") {
        margin: -17px -19px;
      }
      svg {
        position: absolute;
        top: 200%;
        transform: translateY(-50%);
        &:first-child {
          transform: translate(-40%, -50%);
        }
        &:last-child {
          transform: translate(30%, -50%);
        }
      }
    }
  }
}

.dropDown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  z-index: 2;
  top: 80px;
  left: 41.5%;
  transform: translateX(-50%);
  min-width: 736px;
  padding: 36px;
  overflow: hidden;

  border-radius: 0px 0px 12px 12px;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  border-left: 1px solid rgba(255, 255, 255, 0.6);

  &::before {
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 26, 1);
    content: "";
    filter: blur(2px);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  &.resources {
    min-width: 984px;
    padding: 20px 36px;
    left: 46.5%;
  }
}
a {
  @extend %flex-center;
  justify-content: flex-start;
  gap: 8px;
  color: $lightColor;
  transition: all ease-in-out 0.3s;
  text-decoration: none;
  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}

.meetUsWrapper {
  @include responsive("lg") {
    padding: 0 !important;

    .navbarContainer {
      position: fixed;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 58px;
      border-radius: 0;
      border: none;
      padding: 20px;
      height: 64px;

      .navbarSubContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 18px;

        .navbarLinks {
          max-height: 0;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          overflow: hidden;
          transition: all 0.5s ease-in-out;

          li {
            width: 100%;
            padding: 24px 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);

            span {
              display: flex;
              justify-content: space-between;
              svg {
                transform: rotate(-90deg);
              }

              &.activeDropdown {
                svg {
                  transform: rotate(0deg);
                }
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }

      .buttonsContainer {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
      }

      .dropDown {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px;
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        min-width: unset;
        padding: 0;
        padding-top: 14px;

        border: none;
        border-radius: 0;

        li {
          padding: 0 !important;
          border-bottom: none !important;

          .dropdownLink {
            display: flex;
            gap: 8px;
            .dot {
              display: inline-flex;
              width: 4px;
              height: 4px;
              background-color: $lightColor;
              border-radius: 50%;
              margin-left: 8px;
            }

            .text {
              flex: 1;
            }
          }
        }

        &::before {
          display: none;
        }
      }

      &.openNav {
        margin-top: 0px;
        height: 100dvh;
        background: $darkColor;
        backdrop-filter: unset;

        .navbarLinks {
          max-height: 1000px;
        }

        .buttonsContainer {
          max-height: 500px;
        }
      }
    }
  }

  @include responsive("sm") {
    .navbarContainer {
      &.openNav {
        .buttonsContainer {
          width: 100%;
          max-height: 500px;
          flex-direction: column;
          a,
          button {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }
}
