@import "Styles/style.scss";

.featuresDropdown {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 48px;

    .item {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: $lightColor;
        .title {
            @include selectFont("Blinker", 400);
            font-size: 18px;
            line-height: 150%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .content {
            @include selectFont("Blinker", 300);
            font-size: 14px;
            line-height: 155%;
            letter-spacing: 0.28px;
            opacity: 0.7;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    @include responsive("lg") {
        display: none;
    }
}
