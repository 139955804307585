%flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
%flex-align-center {
    display: flex;
    align-items: center;
}

%flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

%position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%position-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;   
    height: 100%;
}